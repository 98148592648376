<template>
  <div class="pane_body">
    <v-card flat color="rgb(255 255 245)">
      <v-toolbar flat dense class="breads">
        <v-breadcrumbs :items="breadcrumb">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>

        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              text
              v-on="on"
              color="success"
              @click.stop="infotypeNewDialog = true"
            >
              new
            </v-btn>
          </template>
          <span>Create new Document Type</span>
        </v-tooltip>
        <SpeDetailNew
          :infotypeNewDialog="infotypeNewDialog"
          :id="id"
          @closeNew="closeNew"
          @submitNew="submitNew"
        />

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" color="info" @click="lists()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-toolbar>
    </v-card>

    <v-container>
      <v-card color="transparent" flat>
        <v-card flat>
          <v-card-text>
            <v-data-table
              loader-height="2"
              :loading="loading"
              :headers="headers"
              :items="datalist"
              :items-per-page="8"
              :sort-by="['id']"
              :sort-desc="false"
              :footer-props="{ itemsPerPageOptions: [8, 16, 24] }"
            >
              <template v-slot:item.name="{ item }">
                {{ item.name | capitalize }}
              </template>
              <template v-slot:item.created="{ item }">
                {{ item.created | myDating }}
              </template>

              <template v-slot:item.active="{ item }">
                <v-icon :color="item.active ? 'blue' : 'red'">
                  {{ item.active ? "mdi-check" : "mdi-close" }}
                </v-icon>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  @click.stop="$set(infoTypeViewDialog, item.id, true)"
                  text
                  x-small
                  color="primary"
                >
                  view
                </v-btn>
                <SpeDetailView
                  v-if="infoTypeViewDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  :infoTypeViewDialog="infoTypeViewDialog[item.id]"
                  @closeView="closeView"
                />

                <v-btn
                  @click.stop="$set(infoTypeEditDialog, item.id, true)"
                  text
                  x-small
                  color="success"
                >
                  edit
                </v-btn>
                <SpeDetailEdit
                  v-if="infoTypeEditDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  :infoTypeEditDialog="infoTypeEditDialog[item.id]"
                  @closeEdit="closeEdit"
                  @submitEdit="submitEdit"
                />

                <v-btn
                  text
                  x-small
                  color="pink"
                  @click.stop="$set(infoTypeRemoveDialog, item.id, true)"
                  >DELETE
                </v-btn>
                <SpeDetailRemove
                  v-if="infoTypeRemoveDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  :infoTypeRemoveDialog="infoTypeRemoveDialog[item.id]"
                  @closeRemove="closeRemove"
                  @submitRemove="submitRemove"
                />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card>
    </v-container>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Utils from "../../../mixins/utils";
import Restful from "../../../services/RestFul";
import SpeDetailNew from "../../../components/admin/specialdetail/newone.vue";
import SpeDetailView from "../../../components/admin/specialdetail/view.vue";
import SpeDetailEdit from "../../../components/admin/specialdetail/edit.vue";
import SpeDetailRemove from "../../../components/admin/specialdetail/remove.vue";
export default {
  components: { SpeDetailNew, SpeDetailView, SpeDetailEdit, SpeDetailRemove },
  props: {
    id: { type: String, default: null },
    name: { type: String, default: null },
  },
  mixins: [Utils],
  data() {
    return {
      infotypeNewDialog: false,
      infoTypeViewDialog: {},
      infoTypeEditDialog: {},
      infoTypeRemoveDialog: {},
      breadcrumb: [
        {
          text: "Dashboard",
          disabled: false,
          to: { name: "admin.dash" },
        },
        {
          text: "Specialities",
          disabled: false,
          to: { name: "admin.specialty" },
        },
        {
          text: "Details",
          disabled: true,
          href: "breadcrumbs_dashboard",
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      loading: false,
      headers: [
        { text: "name", value: "name" },
        { text: "description", value: "description" },
        { text: "active", value: "active" },
        { text: "created", value: "created" },
        { text: "actions", value: "actions", align: "center" },
      ],
      datalist: [],
    };
  },
  created() {
    this.lists();
  },
  methods: {
    lists() {
      this.loading = true;
      let id = parseInt(this._decode(this.id));
      console.log(id);

      let self = this;
      Restful.general.specialDetails
        .list(id)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            this.datalist = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
      this.loading = false;
    },
    closeNew(p) {
      this.infotypeNewDialog = p.state;
    },
    submitNew(p) {
      console.log(p);
      let self = this;
      Restful.general.specialDetails
        .create(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data.message;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data.message;
        });
      this.infotypeNewDialog = p.state;
    },
    closeView(p) {
      this.$set(this.infoTypeViewDialog, p.id, p.state);
    },
    closeEdit(p) {
      this.$set(this.infoTypeEditDialog, p.id, p.state);
    },
    submitEdit(p) {
      console.log(p);
      let self = this;
      Restful.general.specialDetails
        .update(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data.message;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data.message;
        });
      this.$set(this.infoTypeEditDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.infoTypeRemoveDialog, p.id, p.state);
    },
    submitRemove(p) {
      console.log(p);
      let self = this;
      Restful.general.specialDetails
        .remove(p.id)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data.message;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data.message;
        });
      this.$set(this.infoTypeRemoveDialog, p.id, p.state);
    },
  },
};
</script>
